import { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

function FAQ() {
    const [openIndex, setOpenIndex] = useState(null);

    const faqs = [
        {
            question: "How does the EpiQCase attach to my phone?",
            answer: "The EpiQCase uses a strong adhesive backing that securely attaches to your phone case or any flat surface."
        },
        {
            question: "Will it fit my AuviQ auto-injector?",
            answer: "Yes, the EpiQCase is specifically designed to fit all AuviQ auto-injectors perfectly, ensuring a secure fit while maintaining quick access."
        },
        {
            question: "How quickly can I access my auto-injector?",
            answer: "The EpiQCase allows for instant access to your auto-injector when needed. Simply pull the AuviQ out when needed."
        },
        {
            question: "What is the EpiQCase made of?",
            answer: "EpiQCase is made out of PLA plastic, designed to be durable and impact-resistant."
        },
        {
            question: "What is your return policy?",
            answer: "We offer a 30-day satisfaction guarantee. If you're not completely satisfied with your EpiQCase, you can return it for a full refund."
        }
    ];

    return (
        <section id="faq" className="py-16 px-8 bg-gray-50">
            <div className="max-w-3xl mx-auto">
                <h2 className="text-3xl font-bold text-center mb-12">
                    Frequently Asked Questions
                </h2>

                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div 
                            key={index}
                            className="bg-white rounded-lg shadow-md overflow-hidden"
                        >
                            <button
                                onClick={() => setOpenIndex(openIndex === index ? null : index)}
                                className="w-full px-6 py-4 text-left flex justify-between items-center hover:bg-gray-50"
                            >
                                <span className="font-semibold text-lg">{faq.question}</span>
                                {openIndex === index ? 
                                    <FaChevronUp className="text-primary" /> : 
                                    <FaChevronDown className="text-gray-400" />
                                }
                            </button>

                            <div 
                                className={`px-6 transition-all duration-200 ease-in-out ${
                                    openIndex === index ? 'py-4 opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
                                }`}
                            >
                                <p className="text-gray-600">{faq.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default FAQ; 