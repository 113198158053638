import React from 'react';
import CaseBuyButton from '../components/CaseBuyButton';
import caseImg from '../images/noBGImages/IMG_9363.png';
import caseBack from '../images/noBGImages/caseBack-removebg-preview.png';

function Purchase() {
    const specifications = [
        { label: "Material", value: "PLA Plastic" },
        { label: "Dimensions", value: "W: 2.5in x L: 0.8in x H: 3.5in" },
        { label: "Compatibility", value: "All AuviQ auto-injectors" },
        { label: "Mounting", value: "Adhesive backing" }
    ];

    return (
        <div className="max-w-7xl mx-auto px-4 py-16">
            <div className="grid md:grid-cols-2 gap-12">
                {/* Left Column - Images */}
                <div className="space-y-8">
                    <div className="relative h-[400px] bg-gray-50 rounded-lg">
                        <img 
                            src={caseImg} 
                            alt="EpiQCase Front View" 
                            className="absolute inset-0 w-full h-full object-contain p-4"
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="relative h-[200px] bg-gray-50 rounded-lg">
                            <img 
                                src={caseBack} 
                                alt="EpiQCase Back View" 
                                className="absolute inset-0 w-full h-full object-contain p-4"
                            />
                        </div>
                        <div className="relative h-[200px] bg-gray-50 rounded-lg">
                            <img 
                                src={caseImg} 
                                alt="EpiQCase Side View" 
                                className="absolute inset-0 w-full h-full object-contain p-4"
                            />
                        </div>
                    </div>
                </div>

                {/* Right Column - Product Info */}
                <div className="space-y-6">
                    <h1 className="text-3xl font-bold">EpiQCase</h1>
                    
                    {/* Price and Buy Button Section */}
                    <div className="flex flex-col space-y-4">
                        <div className="w-full md:w-auto">
                            <CaseBuyButton />
                        </div>
                    </div>

                    <div className="prose max-w-none">
                        <p className="text-gray-600">
                            A case designed to hold an AuviQ, with the capability to adhere to various surfaces 
                            like the back of a phone or a wall, offers significant advantages for individuals 
                            with severe allergies. This innovative approach ensures that the life-saving 
                            epinephrine auto-injector is not only portable but also highly accessible in a 
                            variety of settings.
                        </p>
                    </div>

                    {/* Specifications */}
                    <div className="border-t border-b py-6 space-y-4">
                        <h2 className="font-semibold text-lg">Specifications</h2>
                        <div className="grid grid-cols-2 gap-4">
                            {specifications.map((spec, index) => (
                                <div key={index} className="space-y-1">
                                    <p className="text-gray-500 text-sm">{spec.label}</p>
                                    <p className="font-medium">{spec.value}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Purchase; 