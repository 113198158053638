import { FaCheck, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import caseImg from '../images/noBGImages/IMG_9350.png';
import caseBack from '../images/noBGImages/caseBack-removebg-preview.png';
import caseImg2 from '../images/noBGImages/IMG_9355.png';
import caseImg3 from '../images/noBGImages/IMG_9363.png';

function ProductFeatures() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    
    const images = [
        { src: caseImg, alt: "EpiQCase Front View" },
        { src: caseBack, alt: "EpiQCase Back View" },
        { src: caseImg2, alt: "EpiQCase Side View" },
        { src: caseImg3, alt: "EpiQCase in Use" }
    ];

    const features = [
        "Compatible with all AuviQ auto-injectors",
        "Easy-access quick-release mechanism",
        "Slim profile design",
        "Strong adhesive backing"
    ];

    // Auto rotate images
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentImageIndex((prevIndex) => 
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(timer);
    }, [images.length]);

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => 
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const previousImage = () => {
        setCurrentImageIndex((prevIndex) => 
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const scrollToFAQ = () => {
        const faqSection = document.getElementById('faq');
        faqSection?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <section id="features" className="py-16 px-8">
            <div className="max-w-7xl mx-auto">
                <div className="flex flex-col md:flex-row items-center justify-center gap-12">
                    {/* Left side - Image Carousel */}
                    <div className="flex-1 flex justify-center items-center">
                        <div className="relative w-full max-w-[500px] h-[400px]">
                            {/* Image Container */}
                            <div className="absolute inset-0 flex items-center justify-center">
                                <img 
                                    src={images[currentImageIndex].src} 
                                    alt={images[currentImageIndex].alt}
                                    className="max-w-full max-h-[400px] w-auto h-auto object-contain mx-auto transition-opacity duration-500"
                                />
                            </div>
                            
                            {/* Navigation Arrows */}
                            <button 
                                onClick={previousImage}
                                className="absolute left-0 top-1/2 -translate-y-1/2 bg-black/20 hover:bg-black/40 text-white p-2 rounded-r-lg transition-colors"
                            >
                                <FaChevronLeft size={24} />
                            </button>
                            <button 
                                onClick={nextImage}
                                className="absolute right-0 top-1/2 -translate-y-1/2 bg-black/20 hover:bg-black/40 text-white p-2 rounded-l-lg transition-colors"
                            >
                                <FaChevronRight size={24} />
                            </button>

                            {/* Dots Indicator */}
                            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2">
                                {images.map((_, index) => (
                                    <button
                                        key={index}
                                        onClick={() => setCurrentImageIndex(index)}
                                        className={`w-2 h-2 rounded-full transition-colors ${
                                            index === currentImageIndex 
                                            ? 'bg-primary' 
                                            : 'bg-gray-400'
                                        }`}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Right side - Features */}
                    <div className="flex-1">
                        <h2 className="text-3xl font-bold mb-6 text-center md:text-left">
                            Designed for Peace of Mind
                        </h2>
                        <p className="text-gray-600 mb-8 text-center md:text-left">
                            Every detail of the EpiQCase has been carefully engineered to provide 
                            reliable protection and instant access to your AuviQ auto-injector.
                        </p>
                        
                        <div className="space-y-4">
                            {features.map((feature, index) => (
                                <div key={index} className="flex items-center gap-3">
                                    <FaCheck className="text-primary flex-shrink-0" />
                                    <span className="text-gray-700">{feature}</span>
                                </div>
                            ))}
                        </div>

                        <button 
                            onClick={scrollToFAQ}
                            className="mt-8 w-full md:w-auto px-6 py-3 bg-primary text-white rounded-lg font-semibold shadow-lg hover:bg-primary/90 active:transform active:scale-95 transition-all"
                        >
                            Learn More
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProductFeatures; 