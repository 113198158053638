import React from 'react';
import { useNavigate } from 'react-router-dom';

function MeetMax() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/About');
        window.scrollTo(0, 0); // Scrolls to top of page
    };

    return (
        <div className="flex flex-col items-center justify-center py-16 px-8">
           
            <button 
                onClick={handleClick}
                className="px-6 py-3 bg-primary text-white rounded-lg font-semibold hover:bg-primary/90 transition-colors"
            >
                Meet Max
            </button>
        </div>
    );
}

export default MeetMax;
