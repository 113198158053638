import { FaShieldAlt, FaRunning, FaMobileAlt } from 'react-icons/fa';

function ProductBenefits() {
    const benefits = [
        {
            icon: <FaShieldAlt className="w-8 h-8 text-primary" />,
            title: "Secure Protection",
            description: "Keeps your AuviQ safely attached and protected"
        },
        {
            icon: <FaRunning className="w-8 h-8 text-primary" />,
            title: "Quick Access",
            description: "Instant retrieval when seconds matter most"
        },
        {
            icon: <FaMobileAlt className="w-8 h-8 text-primary" />,
            title: "Phone Compatible",
            description: "Attaches seamlessly to your phone or any flat surface"
        }
    ];

    return (
        <section className="bg-gray-50 py-16 px-8">
            <div className="max-w-7xl mx-auto">
                <h2 className="text-3xl font-bold text-center mb-12">Why Choose EpiQCase?</h2>
                
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {benefits.map((benefit, index) => (
                        <div 
                            key={index}
                            className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow"
                        >
                            <div className="flex flex-col items-center text-center">
                                <div className="mb-4">
                                    {benefit.icon}
                                </div>
                                <h3 className="text-xl font-semibold mb-2">
                                    {benefit.title}
                                </h3>
                                <p className="text-gray-600">
                                    {benefit.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default ProductBenefits; 