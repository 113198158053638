import React from 'react';
import { Link } from 'react-router-dom';
import caseImg from '../images/noBGImages/IMG_9350.png';

function ProductIntro() {
    const scrollToFeatures = () => {
        const featuresSection = document.getElementById('features');
        featuresSection?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="flex flex-col md:flex-row items-center justify-between px-8 py-16 max-w-7xl mx-auto">
            <div className="flex-1">
                <h1 className="text-4xl font-bold mb-4">EpiQCase</h1>
                <p className="text-xl text-gray-600 mb-8">Keep Your AuviQ Secure & Accessible</p>
                <div className="flex flex-col md:flex-row gap-4">
                    <div className="flex flex-col">
                        <Link to="/Purchase">
                            <button className="w-full md:w-auto h-[50px] px-6 bg-primary text-white rounded-lg font-semibold shadow-lg hover:bg-primary/90 active:transform active:scale-95 transition-all">
                                Buy Now - $15.99
                            </button>
                        </Link>
                        <span className="text-sm text-gray-600 mt-1 text-center">Free Shipping</span>
                    </div>
                    <button 
                        onClick={scrollToFeatures}
                        className="w-full md:w-auto h-[50px] px-6 border-2 border-primary text-primary rounded-lg font-semibold hover:bg-primary/5 active:transform active:scale-95 transition-all"
                    >
                        Learn More
                    </button>
                </div>
            </div>
            <div className="flex-1 flex justify-center mt-8 md:mt-0">
                <img 
                    src={caseImg} 
                    alt="EpiQCase Product" 
                    className="w-full md:w-[300px] lg:w-[350px] h-auto object-contain hover:scale-105 transition-transform duration-300"
                />
            </div>
        </div>
    );
}

export default ProductIntro;