import './App.css';
import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from 'react-router-dom';
import About from './pages/About'; // Adjust the path based on your file structure
import Home from './pages/Home'; // Assuming you have a Home component
import BuyNow from './pages/BuyNow';
import Header from './components/Header';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import { Analytics } from '@vercel/analytics/react';
import ScrollToTop from './components/ScrollToTop';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
    return (
        <ErrorBoundary>
            <div>
                <Router>  
                    <Header/>
                    <Routes>       
                        <Route exact path="/" Component={Home} />
                        <Route path="/Home" Component={Home} />
                        <Route path="/About" Component={About} />
                        <Route path="/Purchase" Component={BuyNow} />
                        <Route path="/PrivacyPolicy" Component={PrivacyPolicy} />
                    </Routes>
                    <Footer />
                    <ScrollToTop />
                </Router>
                <Analytics/>
            </div>
        </ErrorBoundary>
    );
}
  
export default App;
