// src/pages/About.jsx
import React from 'react';
import ProductIntro from '../components/ProductIntro';
import ProductBenefits from '../components/ProductBenefits';
import ProductFeatures from '../components/ProductFeatures';
import SafetyTrust from '../components/SafetyTrust';
import FAQ from '../components/FAQ';
import MeetMax from '../components/MeetMax';

function Home() {
    return (
        <div>
            <ProductIntro />
            <ProductBenefits />
            <ProductFeatures />
            <SafetyTrust />
            <FAQ />
            <MeetMax />
        </div>
    );
}

export default Home;