// src/pages/About.jsx
import React from 'react';
import maxImg from '../images/me.jpg';

function About() {
    return (
        <div className="max-w-4xl mx-auto px-4 py-16">
            {/* Personal Story Section */}
            <div className="text-center mb-16">
                <h1 className="text-4xl font-bold mb-6">Why I Created EpiQCase</h1>
                <p className="text-xl text-gray-600 mb-8">
                    Living with severe allergies shouldn't mean living in constant worry.
                </p>
            </div>

            {/* Founder Story */}
            <div className="grid md:grid-cols-2 gap-12 items-center mb-16">
                <div className="space-y-6">
                    <p className="text-gray-700 leading-relaxed">
                        Hi, I'm Max, and like many of you, I live with severe allergies. For years, 
                        I struggled with the anxiety of wondering if I had my auto-injector with me 
                        and whether I could access it quickly enough in an emergency.
                    </p>
                    <p className="text-gray-700 leading-relaxed">
                        After one too many close calls and forgotten auto-injectors, I knew there 
                        had to be a better way. That's why I created EpiQCase - to give myself and 
                        others like me the peace of mind we deserve.
                    </p>
                </div>
                <div className="flex justify-center">
                    <img 
                        src={maxImg} 
                        alt="Max, founder of EpiQCase" 
                        className="rounded-lg shadow-lg max-w-[300px]"
                    />
                </div>
            </div>

            {/* Impact Section */}
            <div className="bg-gray-50 rounded-xl p-8 mb-16">
                <h2 className="text-2xl font-bold mb-6">The Peace of Mind I Found</h2>
                <div className="space-y-4">
                    <p className="text-gray-700 leading-relaxed">
                        With EpiQCase attached to my phone, I never leave home without my AuviQ anymore - 
                        because I never leave home without my phone. It's become as natural as grabbing 
                        my keys or wallet.
                    </p>
                    <p className="text-gray-700 leading-relaxed">
                        Whether I'm at a restaurant, hiking with friends, or just going about my day, 
                        I know my auto-injector is right there with me. The quick-release design means 
                        I can access it in seconds if needed - and that confidence has changed how I 
                        live my life.
                    </p>
                </div>
            </div>

            {/* Mission Statement */}
            <div className="text-center">
                <h2 className="text-2xl font-bold mb-6">Our Mission</h2>
                <p className="text-gray-700 leading-relaxed">
                    EpiQCase isn't just a product - it's my commitment to helping others with severe 
                    allergies live more confidently. Every case we make is designed with one goal: 
                    to ensure you never have to worry about having your auto-injector when you need 
                    it most.
                </p>
            </div>
        </div>
    );
}

export default About;
