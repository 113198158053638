import React from 'react';
import { useNavigate } from 'react-router-dom';

function Footer() {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    return (
        <footer className="bg-gray-50 pt-8 pb-4">
            {/* Main Footer Content */}
            <div className="max-w-7xl mx-auto px-4">
                <div className="flex justify-center gap-8 mb-8">
                    <button 
                        onClick={() => handleNavigation('/About')}
                        className="px-6 py-2 text-gray-600 hover:text-primary transition-colors font-semibold"
                    >
                        About
                    </button>
                    <button 
                        onClick={() => handleNavigation('/Purchase')}
                        className="px-6 py-2 bg-primary text-white rounded-lg font-semibold hover:bg-primary/90 transition-colors"
                    >
                        Buy Now
                    </button>
                    <button 
                        onClick={() => handleNavigation('/')}
                        className="px-6 py-2 text-gray-600 hover:text-primary transition-colors font-semibold"
                    >
                        Home
                    </button>
                </div>

                {/* Divider */}
                <div className="border-t border-gray-200 my-4"></div>

                {/* Bottom Section */}
                <div className="flex flex-col items-center">
                    <button 
                        onClick={() => handleNavigation('/PrivacyPolicy')}
                        className="text-sm text-gray-500 hover:text-gray-700 transition-colors"
                    >
                        Privacy Policy
                    </button>
                    <p className="text-sm text-gray-400 mt-2">
                        © {new Date().getFullYear()} EpiQCase. All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;