import React from 'react';
import { FaShieldAlt, FaClock, FaCheck } from 'react-icons/fa';

function SafetyTrust() {
    return (
        <section className="py-16 px-8 bg-gray-50">
            <div className="max-w-7xl mx-auto">
                <h2 className="text-3xl font-bold text-center mb-12">
                    Your Safety Is Our Priority
                </h2>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="flex flex-col items-center text-center p-6">
                        <FaShieldAlt className="w-12 h-12 text-primary mb-4" />
                        <h3 className="text-xl font-semibold mb-3">Reliable Protection</h3>
                        <p className="text-gray-600">
                            Tested for durability and secure holding of your auto-injector in everyday situations
                        </p>
                    </div>

                    <div className="flex flex-col items-center text-center p-6">
                        <FaClock className="w-12 h-12 text-primary mb-4" />
                        <h3 className="text-xl font-semibold mb-3">Quick Access</h3>
                        <p className="text-gray-600">
                            Designed for immediate retrieval when every second counts
                        </p>
                    </div>

                    <div className="flex flex-col items-center text-center p-6">
                        <FaCheck className="w-12 h-12 text-primary mb-4" />
                        <h3 className="text-xl font-semibold mb-3">Peace of Mind</h3>
                        <p className="text-gray-600">
                            Always know where your auto-injector is, giving you confidence to live life fully
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SafetyTrust; 