import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

function Header() {
  return (
    <div className="z-50">
      <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
        <Link to='/About'>
          <Button className='aboutbutton'>About</Button>
        </Link>

        <Link to="/" className="text-center">
          <span className="text-2xl font-bold font-mono hover:text-primary transition-colors">
            EpiQCase
          </span>
        </Link>

        <Link to='/Purchase'>
          <Button 
            variant="contained" 
            className='bg-primary hover:bg-primary/90 text-white'
          >
            Buy Now
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default Header;